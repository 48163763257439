<div *ngFor="let profile of profiles">
    <div class="relative flex justify-center md:justify-end w-full overflow-y-visible h-[500px] z-30 mt-10">
        <div class="absolute w-3/5 md:w-1/2 xl:w-1/3 top-10">
            <div class="z-10">
                <img class="shadow-lg max-w-[180px] md:max-w-[220px] mb-10" [src]="getFullImagePath(profile.attributes.cv_image_cover.data.attributes.url)" alt="Resume cover" />
                <h1 class="leading-none text-4xl">Download Resume Presentation</h1>
                <button class="btn tertiary" (click)="downloadPDF(profile.attributes.cv_upload.data.attributes.url)">Download</button>
            </div>
        </div>   
    </div>
    <div class="w-full block md:flex content-center relative">
        <img class="absolute xs:bottom-[310px] bottom-[280px] sm:bottom-[260px] md:bottom-24 lg:bottom-16 w-full -z-[1]" src="../../assets/footer_gray.svg" alt="">
        <svg class="absolute w-0 h-0">
            <clipPath id="diagonal" clipPathUnits="objectBoundingBox"><path d="M0,0 H0.475 L1,0.199,1,1 H0"></path></clipPath>
        </svg>
        <div class="clippath md:clip-path-none w-full md:w-[500px] 2xl:w-1/3 bg-orange-400 flex flex-col items-center justify-center -mr-1 py-12">
            <div class="flex items-center gap-x-7">
                <button *ngFor="let link of profiles[0]?.attributes?.social_links" class="btn secondary__fill p-0">
                    <a [href]="link.link" target="_blank">
                      <fa-icon [icon]="['fab', link.social]" class="text-5xl"></fa-icon>
                    </a>
                  </button>
            </div>
            <button routerLink="../contact" class="btn secondary">Contact</button>
        </div>
        <div class="relative w-full">
            <img class="w-full hidden md:block" src="../../assets/footer.svg" alt="">
            <div class="w-full md:w-auto h-44 md:h-auto bg-dark-blue-950 md:bg-transparent flex flex-col justify-center items-center">
                    <div class="md:w-full p-7 text-white absolute top-8 md:top-auto md:bottom-0 bg-dark-blue-950 md:left-0">&copy; {{ currentYear }} bettyorganero.com. All rights reserved.</div>
                    <div class="flex justify-center">
                        <div class="flex items-center absolute bottom-0 md:right-0">
                            <div class="w-16 h-16 pr-8 flex items-center justify-center border-solid border-r-[1px] border-r-gray-100">
                                <a routerLink="./home"><img class="w-full" src="../../assets/logo_miscelaneo.svg" alt="miscelaneo design"></a>
                            </div>
                            <div class="h-16 px-5 md:px-8 flex items-center" *ngFor="let profile of profiles">
                                <a routerLink="./home"><p class="text-base font-black capitalize g-title">{{ profile.attributes.nickname }}</p></a>
                            </div>
                        </div>
                    </div>
            </div>
            
        </div>       
    </div>


</div>
