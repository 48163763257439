import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of} from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ApiService } from "../services/api.service";
import { HttpErrorResponse } from "@angular/common/http";
import { fade } from "../animations";
import { FullImagePathService } from "../services/full-image-path.service";

interface Project {
  id: number;
  name: string;
  description: string;
  image: string;
  gallery: string[];
  software: string[];
  cms: string[];
  libraries_frameworks: string[];
  languages: string[];
  //categories: string[];
  client: string;
  link: string;
  slug: string;
  categories: { id: number, name: string }[];
 }

interface Entry<T> {
  id: number;
  attributes: T;
}

interface ImageData {
  id: number;
  attributes: {
    name: string;
    formats: {
      thumbnail: {
        url: string;
      };
      small: {
        url: string;
      }
    };
  };
}

interface GalleryData {
  id: number;
  attributes: {
    name: string;
    url: string;
    formats: {
      medium: {
        url: string;
      };
    };
  };
}


interface Category {
  id: number;
  name: string;
  attributes?: {
    name: string;
  };
}


interface Response {
  data: Entry<Project & { image: { data: ImageData[] }; categories: Category[]; gallery: { data: GalleryData[] }; } >[];
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  animations: [ fade, ]
})

export class ProjectsComponent implements OnInit {
  error: any | undefined;
  projects$: Observable<Project[]> | undefined;

  categories: Category[] = [];
  //selectedCategoryId: string | undefined = '';

  selectedCategoryId: string | number = '';

  showProjects: boolean = true;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private fullImagePathService: FullImagePathService
  ) {}

  ngOnInit(): void {

    this.apiService.getCategoryData().subscribe(data => {
      this.categories = data.data.map((entry: any) => ({
        id: entry.id,
        name: entry.attributes.name
      }));
    });

    this.route.queryParams.subscribe((params) => {
      const categoryId = params['categoryId'];
      //this.selectedCategoryId = categoryId || '';
      this.selectedCategoryId = params["categoryId"] || '';
      this.filterProjects();
    });


    this.route.queryParams.subscribe((params) => {
      const categoryId = params['categoryId'];
      this.projects$ = this.apiService.getProjectsData().pipe(
        catchError((error) => this.handleError(error)),
        map((response) => this.mapProjects(response.data, categoryId))
      );
    });
  }

  // Event handler for selected option change
  onCategorySelected(categoryId: string | number): void {
    // Update the URL with the selected category ID
    this.selectedCategoryId = categoryId;
    this.updateUrlParams({ categoryId });
  }

  // Function to update query parameters in the URL
  updateUrlParams(params: { [key: string]: any }): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge' // Merge with existing query parameters
    });
  }


  filterProjects(categoryId?: string): void {
    const categoryIdString = categoryId?.toString();
    this.projects$ = this.apiService.getProjectsData().pipe(
      catchError((error) => this.handleError(error)),
      map((response) => {
        const filteredProjects = this.mapProjects(response.data, categoryId);
        this.showProjects = filteredProjects.length > 0; // Update showProjects flag
        console.log('Selected category ID:', this.selectedCategoryId);
        return filteredProjects;
      })
    );
  }


  private mapProjects(data: any[], categoryId: string | undefined): Project[] {
    // Ordenar los proyectos por la fecha de publicación (publishedAt)
    data.sort((a, b) => {
      const dateA = new Date(a.attributes.publishedAt);
      const dateB = new Date(b.attributes.publishedAt);
      return dateB.getTime() - dateA.getTime(); // Ordenar de forma descendente (más reciente primero)
    });
  
    return data.map((project: any) => {
      const { id, attributes } = project;
      const categories = this.extractCategories(attributes.categories);
      const gallery = this.extractGallery(attributes.gallery);
  
      return {
        id,
        name: attributes.name,
        client: attributes.client,
        link: attributes.link,
        slug: attributes.slug,
        description: attributes.description,
        image: attributes.image.data[0]?.attributes.formats.small.url || '',
        gallery,
        software: attributes.software || [],
        cms: attributes.cms || [],
        libraries_frameworks: attributes.libraries_frameworks || [],
        languages: attributes.languages || [],
        categories,
      };
    }).filter((project) => !categoryId || project.categories.some((cat) => cat.id === +categoryId));
  }
  

  private extractCategories(categories: any): { id: number; name: string }[] {
    const extractedCategories: { id: number; name: string }[] = [];
  
    if (categories && Array.isArray(categories)) {
      // Case where categories is an array directly containing category objects
      categories.forEach((category: any) => {
        if (category && typeof category === 'object' && category.id) {
          // Ensure that category object has an 'id' property
          const categoryName = category.name || 'Unknown'; // Fallback to 'Unknown' if name is not present
          extractedCategories.push({ id: category.id, name: categoryName });
        }
      });
    } else if (categories && categories.data && Array.isArray(categories.data)) {
      // Case where categories is an object with a 'data' property containing category objects
      categories.data.forEach((category: any) => {
        if (category && typeof category === 'object' && category.id && category.attributes && category.attributes.name) {
          // Ensure that category object has an 'id' property and 'attributes' property with 'name'
          extractedCategories.push({ id: category.id, name: category.attributes.name });
        }
      });
    }
  
    return extractedCategories;
  }
    
  
  

  private extractGallery(gallery: any): string[] {
    if (gallery) {
      if (Array.isArray(gallery.data) && gallery.data.length > 0) {
        return gallery.data.map((item: any) => (item.attributes ? item.attributes.url : ''));
      } else if (gallery.attributes) {
        return [gallery.attributes.url];
      }
    }
    return [];
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    this.error = error;
    return of();
  }

  getFullImagePath(relativePath: string): string {
    return this.fullImagePathService.getFullImagePath(relativePath);
  }

  
}


