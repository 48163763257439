// project.component.ts

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ApiService } from '../services/api.service';
import { FullImagePathService } from  '../services/full-image-path.service';

interface Project {
  data: {
    id: number;
    attributes: {
      name: string;
      client: string;
      description: string;
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
      software: string[];
      cms: string[] | null;
      libraries_frameworks: string[] | null;
      languages: string[] | null;
      link: string | null;
      image: {
        data: {
          id: number;
          attributes: {
            name: string;
            url: string;
            formats: {
              thumbnail: {
                url: string;
              };
            };
          };
        }[];
      };
      gallery: {
        data: {
          id: number;
          attributes: {
            name: string;
            url: string;
            formats: {
              thumbnail: {
                url: string;
              };
            };
          };
        }[];
      };
      categories: {
        data: {
          id: number;
          attributes: {
            name: string;
            slug: string;
          };
        }[];
      };
    }
  }
  
  
}

interface Category {
  id: number;
  name: string;
  slug: string;
}

interface ResponseData {
  data: Category[];
}


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})

export class ProjectComponent implements OnInit {
  //project$: Observable<any> | undefined;
  project$: Observable<Project | null> | undefined;
  error: any | undefined;

  categories: { [name: string]: number } = {};
  selectedCategoryId: number | null = null;

  showModal = false;

  modalImageUrl: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private fullImagePathService: FullImagePathService
  ) {}

  ngOnInit(): void {
    this.apiService.getCategoryData().pipe(
      catchError((error) => {
        //console.error('Error fetching categories:', error);
        return of({ data: [] });
      })
    ).subscribe((response: { data: any[]; }) => {
      response.data.forEach(category => {
        this.categories[category.name] = category.id;
      });

      const categoryIdParam = this.route.snapshot.queryParamMap.get('categoryId');
      this.selectedCategoryId = categoryIdParam ? +categoryIdParam : null;

      this.fetchProjectData();
    });
  }

  fetchProjectData(): void {
    const projectId = this.route.snapshot.paramMap.get('id');

    if (projectId) {
      this.project$ = this.apiService.getProjectById(projectId).pipe(
        //tap((projectData) => console.log('Project Data:', projectData)),
        catchError((error) => {
          this.error = error;
          return of(null);
        })
      );
    }
  }

  // fetchProjectData(): void {
  //   const slug = this.route.snapshot.paramMap.get('slug');
  
  //   if (slug) {
  //     this.project$ = this.apiService.getProjectBySlug(slug).pipe(
  //       catchError((error) => {
  //         this.error = error;
  //         return of(null);
  //       })
  //     );
  //   }
  // }

  isImage(url: string): boolean {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  }
  
  isVideo(url: string): boolean {
    return /\.(mp4|mov|avi|mkv)$/i.test(url);
  }
  

  getFullImagePath(relativePath: string): string {
    return this.fullImagePathService.getFullImagePath(relativePath);
  }

  // Handle category filter change
  onCategoryFilterChange(categoryId: number | null): void {
    this.selectedCategoryId = categoryId;
    this.fetchProjectData();
  }

  openModal(imageUrl: string): void {
    this.modalImageUrl = imageUrl;
    this.showModal = true;
  }
  
    toggleModal(showModal: boolean): void {
      this.showModal = showModal;
    }

}