<div [ngClass]="{ bgWhite: scrolled }" class="bg-white/0 fixed top-0 left-0 w-full px-3 md:px-20 flex flex-row items-center justify-between z-50 transition-all ease-in-out duration-500">
  <div class="flex flex-row">
    <div class="w-12 md:w-20 lg:w-auto h-12 md:h-20 lg:h-24 pr-5 md:pr-10 flex items-center justify-center border-solid border-r-2 border-r-gray-100 cursor-pointer">
      <a (click)="navigateToHome()"><img class="w-full" src="../../assets/logo_miscelaneo.svg" alt="miscelaneo design"></a>
    </div>
    <div class="h-12 md:h-20 lg:h-24 px-5 md:px-10 flex items-center" *ngFor="let profile of profiles">
       <a (click)="navigateToHome()" class="text-base lg:text-xl font-black capitalize g-title cursor-pointer">{{ profile.attributes.nickname }}</a>
    </div>
  </div>

    <nav *ngIf="menus$ | async as menuData" class="relative">
      <ul class="flex-row items-center hidden lg:flex">
        <li *ngFor="let menu of menuData.data" class="px-10">
          <a *ngIf="menu.attributes.slug !== 'home'; else homeLink" [routerLink]="[menu.attributes.slug]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: menu.attributes.slug === '' }">{{ menu.attributes.title }}</a>
          <ng-template #homeLink>
            <a (click)="navigateToHome()" routerLinkActive="active" class="cursor-pointer">{{ menu.attributes.title }}</a>
          </ng-template>
        </li>
      </ul>
  
      <div class="w-32 block lg:hidden relative">
        <button type="button" (click)="toggleMenu()" class="float-right">
            <div class="icon-bar" [@hamburguerX]="showMenus$ ? 'hamburguer' : 'topX'"></div>
            <div class="icon-bar" [@hamburguerX]="showMenus$ ? 'hamburguer' : 'hide'"></div>
            <div class="icon-bar" [@hamburguerX]="showMenus$ ? 'hamburguer' : 'bottomX'"></div>
        </button>    
        
        <ng-container *ngIf="!showMenus$">
          <ul [@fade] class="block bg-white absolute right-0 top-9 rounded-lg pt-6 p-2 z-30">
            <li *ngFor="let menu of menuData.data" class="px-10">
              <a *ngIf="menu.attributes.slug !== 'home'; else homeLink" [routerLink]="[menu.attributes.slug]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: menu.attributes.slug === '' }">{{ menu.attributes.title }}</a>
              <ng-template #homeLink>
                <a (click)="navigateToHome()" routerLinkActive="active">{{ menu.attributes.title }}</a>
              </ng-template>
            </li>
          </ul>
        </ng-container>
        
      </div>
    </nav>  
</div>


