<main class="p-0 lg:px-24 mt-24 lg:mt-44">
  <div *ngIf="project$ | async as projectData" class="w-full xl:container mx-auto">

    <div class="flex flex-wrap justify-between items-stretch">
        <div class="w-full lg:w-1/2 xl:w-1/3 order-2 lg:order-1 px-12">
          <h1 class="capitalize mt-5 lg:mt-0">{{ projectData.data.attributes.name }}</h1>
          <p class="text-sm font-bold text-gray-600">Client: {{ projectData.data.attributes.client }}</p>
          <p class="mt-3">{{ projectData.data.attributes.description }}</p>
          <!-- Category filter -->
          <ul *ngIf="projectData.data.attributes.categories?.data && projectData.data.attributes.categories.data.length > 0" class="flex flex-wrap items-center mt-2">
            <li *ngFor="let categoriesItem of projectData.data.attributes.categories.data">
              <a [routerLink]="['/projects']" [queryParams]="{ categoryId: categoriesItem.id }" class="chip cat mb-1">
                {{ categoriesItem.attributes.name }}
              </a>
            </li>
          </ul>
          <div *ngIf="projectData.data.attributes.link && projectData.data.attributes.link.length > 0;" class="flex items-center mt-1.5 lg:mt-4">
            <a href="{{ projectData.data.attributes.link }}" target="_blank" class="btn px-0 font-sans font-medium hover:text-orange-500 underline">
              <fa-icon [icon]="['fas', 'link']" class="text-lg lg:text-xl mr-2"></fa-icon>Visit the website
            </a>            
          </div>
        </div>
          <!-- Project Image Thumb-->
        <img class="w-full lg:w-1/2 xl:w-2/3 object-cover order-1 lg:order-2" *ngIf="projectData.data.attributes.image?.data?.[0]" [src]="getFullImagePath(projectData.data.attributes.image.data[0].attributes.url)" [alt]="projectData.data.attributes.image.data[0].attributes.name">
    </div>

    <div class="w-full flex flex-nowrap items-center justify-start xl:justify-center overflow-x-scroll my-1 md:my-10 lg:my-16 h-64 md:h-72 gap-10 md:gap-20 lg:gap-22">
        <!-- Libraries & Frameworks -->
        <div class="tecnic">
          <h3>Libraries, Frameworks & Tools:</h3>
          <div class="flex gap-5">
            <ul *ngIf="projectData.data.attributes.libraries_frameworks && projectData.data.attributes.libraries_frameworks.length > 0;">
              <li *ngFor="let item of projectData.data.attributes.libraries_frameworks">
                <img src="../../assets/icons/{{ item }}.svg" alt="">
              </li>
            </ul>  
            <ul *ngIf="projectData.data.attributes.software && projectData.data.attributes.software.length > 0;">
              <li *ngFor="let item of projectData.data.attributes.software"><img src="../../assets/icons/{{ item }}.svg" alt=""></li>
            </ul> 
          </div>
        </div>
        
        <!-- Software -->
        <!-- <div class="tecnic" *ngIf="projectData.data.attributes.software && projectData.data.attributes.software.length > 0;">
          <h3>Tools:</h3>
          <ul>
            <li *ngFor="let item of projectData.data.attributes.software"><img src="../../assets/icons/{{ item }}.svg" alt=""></li>
          </ul>  
        </div> -->

        <!-- Languages -->
        <div class="tecnic" *ngIf="projectData.data.attributes.languages && projectData.data.attributes.languages.length > 0;">
          <h3>Languages:</h3>
          <ul>
            <li *ngFor="let item of projectData.data.attributes.languages"><img src="../../assets/icons/{{ item }}.svg" alt=""></li>
          </ul>    
        </div>

        <!-- CMS -->
        <div class="tecnic" *ngIf="projectData.data.attributes.cms && projectData.data.attributes.cms.length > 0;">
          <h3>CMS:</h3>
          <ul>
            <li *ngFor="let item of projectData.data.attributes.cms"><img src="../../assets/icons/{{ item }}.svg" alt=""></li>
          </ul>  
        </div> 
             
    </div>    
</div>
</main>

<!-- Gallery -->
<div *ngIf="project$ | async as projectData" class="w-full mb-60">
      <div class="" *ngIf="projectData.data.attributes.gallery?.data && projectData.data.attributes.gallery.data.length > 0">
        <div class="gallery">
          <ul>
            <ng-container *ngFor="let galleryItem of projectData.data.attributes.gallery.data">
              <ng-container *ngIf="isImage(galleryItem.attributes.url)">
                <li (click)="openModal(getFullImagePath(galleryItem.attributes.url))">
                  <div class="w-full max-h-[230px] md:max-h-[360px] lg:max-h-[450px] overflow-hidden relative">
                    <img [src]="getFullImagePath(galleryItem.attributes.url)" [alt]="galleryItem.attributes.name">
                  </div>
                </li>
              </ng-container>
              <ng-container *ngIf="isVideo(galleryItem.attributes.url)">
                <li (click)="openModal(getFullImagePath(galleryItem.attributes.url))">
                  <div class="w-full max-h-[230px] md:max-h-[360px] lg:max-h-[450px] overflow-hidden relative">
                    <video [src]="getFullImagePath(galleryItem.attributes.url)" controls></video>
                  </div>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    
    

    <app-modal [showModal]="showModal" (toggleModal)="toggleModal($event)" [imageUrl]="modalImageUrl" modalClass="modal-white"></app-modal>
</div>





