import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { fadePage } from './animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [fadePage] // register the animation
})

export class AppComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Bettyorganero Portfolio');
  }

}
