<main>
  <div class="relative flex flex-col justify-items-center mb-20 md:mb-32">
    <div class="relative flex flex-col justify-center items-center mb-10 md:mb-20">
      <!-- <p class="w-full font-black text-8xl text-center tracking-[1rem] z-10 relative">B<span class="text-9xl text-orange-400 -ml-10">O</span><span class="text-9xl -ml-14 text-orange-400 absolute">O</span>  K</p> -->
      <div class="container text-center mb-5">
        <h1 class="text-gray-700 text-2xl lg:text-4xl">Book</h1>
      </div>
      <div class="flex items-center justify-center bg-gray-50 rounded-full px-3 py-2">
        <p class="text-xs text-gray-400 font-normal">Short works by:</p>  
       <app-custom-dropdown [(ngModel)]="selectedCategoryId" (selectedOptionChange)="onCategorySelected($event)" [options]="categories" class="ml-2 text-sm text-gray-400 font-light"></app-custom-dropdown>
      </div>
    </div>
  
    <ul *ngIf="(projects$ | async)?.length; else noProjectsTemplate" class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-8 xl:gap-14">
    
    <li @fade *ngFor="let project of (projects$ | async) as projectsList" class="bg-gray-50 shadow-lg">
  
            <!-- // thumb -->
            <div class="w-full h-64 md:h-56 xl:h-52 relative">
                <svg class="svg">
                  <clipPath id="thumb-clip-path" clipPathUnits="objectBoundingBox"><path d="M0.749,0.999 L0.001,0.999 L0.001,0.001 L0.999,0.001 L0.999,0.565 L0.749,0.999 M0.001,0.002 L0.001,0.998 L0.748,0.998 L0.999,0.564 L0.999,0.002 L0.001,0.002 M0,0 L1,0 L1,0.565 L0.749,1 L0,1 L0,0"></path></clipPath>
                </svg>
                <div [routerLink]="['/project', project.slug]" [style]="{ 'background-image': 'url(' + getFullImagePath(project.image) + ')' }" class="clip cursor-pointer"></div>
                <button [routerLink]="['/project', project.slug]" class="absolute right-4 -bottom-4 btn p-0 text-yellow-400 hover:text-black"><fa-icon [icon]="['fas', 'eye']" class="text-2xl "></fa-icon></button>
            </div>
            
            <a [routerLink]="['/project', project.slug]"><h3 class="capitalize mt-5 font-oneday px-5 hover:underline">{{project.name}}</h3></a> 
            <!-- Categories with links -->
             <ul *ngIf="project.categories && project.categories.length > 0" class="flex flex-wrap items-center px-5 pb-5">
                <li *ngFor="let category of project.categories">
                      <a [routerLink]="['/projects']" [queryParams]="{ categoryId: category.id }" class="chip cat mb-1">
                        {{ category.name }}
                      </a>
                </li>
             </ul>
      </li>   
    </ul>
  </div>
  <!-- ng-template to display when there are no projects -->
  <ng-template #noProjectsTemplate>
    <div class="w-full flex justify-center items-center">
      <p>No projects found.</p>
    </div>
  </ng-template>
</main>

