<!-- custom-dropdown.component.html -->
<div class="custom-dropdown" [class.open]="isOpen" (click)="toggleDropdown()" tabindex="0">
    <div class="selected relative flex justify-between">
        <span class="">{{ getSelectedOptionName() }}</span> 
        <fa-icon [icon]="['fas', 'chevron-down']" class="text-sm text-gray-300" [@iconFade]="isOpen ? 'open' : 'closed'"></fa-icon>
        <select [(ngModel)]="selectedOption" class="pointer-events-none z-10 appearance-none bg-transparent absolute text-orange-400" aria-labelledby="selectLabel">
        <option value="" selected>All</option>
        <option *ngFor="let option of options" [value]="option.id">{{ option.name }}</option>
      </select>
    </div>
    <ul [@fade]="isOpen ? 'open' : 'void'" class="options" [ngClass]="{ 'options-open': isOpen }">
        <li (click)="selectOption('')">All</li> 
        <li *ngFor="let option of options" (click)="selectOption(option.id)">
            {{ option.name }}
            <input type="hidden" [value]="option.id" name="{{ option.name }}">
        </li>
    </ul>
  </div>
  
  

