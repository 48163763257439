<div [ngClass]="modalClass">
    <div
    class="modal-overlay"
    [@openClose]="showModal ? 'open' : 'close'"
    (click)="toggle()"
    >
    <div class="modal-content" *ngIf="modalcontent">
        <img class="w-[35px] md:w-[45px] absolute -top-5 left-10 quotes" src="../../assets/quotes.svg" alt="">
        <img class="w-[35px] md:w-[45px] absolute right-8 -bottom-5 md:right-11 rotate-180 quotes" src="../../assets/quotes.svg" alt="">
        <div class="ref" *ngIf="modalcontent">      
            <p [innerHTML]="modalcontent.text"></p>
            <a class="ref_person" [href]="'mailto:'+(modalcontent.mail)">
            <p>{{ modalcontent.person }}</p>
            <small>{{ modalcontent.position }}</small>
            </a>
        </div>
    </div>

        <div class="modal-content gallery" *ngIf="imageUrl">
            <img [src]="imageUrl" [alt]="imageUrl">
        </div>

        <div class="btn modal-close" 
            (click)="closeButton()"
            >
                <fa-icon [icon]="['fas', 'circle-xmark']"></fa-icon>
        </div>
    </div>
</div>
