import { trigger, state, style, animate, transition, query, AUTO_STYLE, } from '@angular/animations';

export const fadePage = trigger('fadePage', [
  // The '* => *' will trigger the animation to change between any two states
  transition('* => *', [
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(
      ':leave',
      // here we apply a style and use the animate function to apply the style over 0.3 seconds
      [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
      { optional: true }
    ),
    query(
      ':enter',
      [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
      { optional: true }
    )
  ])
]);

// Fade
export const fade = trigger('fade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(500, style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate(500, style({ opacity: 0 }))
  ])
]);

// Hamburguer menu icon
export const hamburguerX = trigger('hamburguerX', [
        state('hamburguer', style({})),
        // style top bar to create the X
        state(
          'topX',
          style({
            transform: 'rotate(45deg)',
            transformOrigin: 'left',
            margin: '6px',
          })
        ),
        // hides element when create the X (used in the middle bar)
        state(
          'hide',
          style({
            opacity: 0,
          })
        ),
        // style bottom bar to create the X
        state(
          'bottomX',
          style({
            transform: 'rotate(-45deg)',
            transformOrigin: 'left',
            margin: '6px',
          })
        ),
        transition('* => *', [
          animate('0.2s'), // controls animation speed
        ]),
]);

// Collapse
const DEFAULT_DURATION = 300;
export const collapse = trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden', opacity: '0' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
])