import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  
  private baseUrl = 'https://book-strapi.miscelaneo.net/api';
  //private baseUrl = 'http://localhost:1337/api';

  constructor(private http: HttpClient) {}

  getMenuData(): Observable<any> {
    const params = new HttpParams().set('populate', '*');
    return this.http.get<any>(`${this.baseUrl}/menus`, { params });
  }
  
  getProfileData(): Observable<any> {
    const params = new HttpParams().set('populate', 'deep');
    return this.http.get<any>(`${this.baseUrl}/profiles`, { params });
  }

  getProjectsData(): Observable<any> {
    const params = new HttpParams().set('populate', '*');
    return this.http.get<any>(`${this.baseUrl}/projects`, { params });
  }

  getProjectById(projectId: string): Observable<any> {
    const opts = { params: { populate: "*" } };
    return this.http.get<any>(`${this.baseUrl}/projects/${projectId}`, opts);
  }
  
  getCategoryData(): Observable<any> {
    const params = new HttpParams().set('populate', '*');
    return this.http.get<any>(`${this.baseUrl}/categories`, { params });
  }
 
  getProjectBySlug(slug: string): Observable<any> {
    const opts = { params: { populate: "*" } };
    return this.http.get<any>(`${this.baseUrl}/projects?slug=${slug}`, opts);
  }


  // Add more API calls as needed
}
