// profile.component.ts

import { Component, OnInit, AfterViewInit, OnDestroy, Input, HostListener} from '@angular/core';
import { ApiService } from '../services/api.service';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FullImagePathService } from  '../services/full-image-path.service';

import { collapse } from '../animations';


// const DEFAULT_DURATION = 300;

interface Profile {
  id: number;
  attributes: {
    name: string;
    description: string;
    profession: string;
    degree: string;
    nickname: string;
    slug: string;
    picture: PictureProfile[];
    references: string[];
    mentions: Mention[];
  };
}

interface PictureProfile {
  data: {
    id: number;
    attributes: {
      url: string;
    }
  }
}

interface Mention {
  id: number;
  m_name: string;
  m_description: { children: { text: string }[] }[];
  m_tags: string[];
  skills_types: SkillType[];
}

interface SkillType {
  id: number;
  cat_skills: string;
  skills: Skills[];
}

interface Skills {
  id: number;
  skill_name: string;
  percent: number;
}



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [ collapse, ]
})


  export class ProfileComponent implements OnInit {
  profiles: any[] = [];
  collapsed = false;
  state: string = 'open';
  charts: am4charts.RadarChart[] = [];
  activeSkillTypeIndex: number[] = [];
  collapsedStates: { collapsed: boolean, state: string }[] = [];

  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private fullImagePathService: FullImagePathService
    ) {}

    ngOnInit(): void {
      this.apiService.getProfileData().subscribe({
        next: (data) => {
          this.profiles = data.data;
          this.profiles.forEach((selectedProfile: Profile) => {
            //console.log('Profile Picture:', selectedProfile.attributes.picture);
            if (selectedProfile.attributes.mentions && selectedProfile.attributes.mentions.length > 0) {
              selectedProfile.attributes.mentions.forEach((mention: Mention, mentionIndex: number) => {
                if (mention.skills_types && mention.skills_types.length > 0) {
                  mention.skills_types.forEach((skillType: SkillType) => {
                    // this.createRadarChart(selectedProfile, skillType.skills, mentionIndex);
                  });
                }
              });
            }
          });
        },
        error: (error) => {
          console.error('Error fetching profiles:', error);
        }
      });
    }

    ngOnDestroy(): void {
      this.charts.forEach(chart => {
        chart.dispose();
      });
    }

    ngAfterViewInit(): void {
  
      this.profiles.forEach((profile) => {
        profile.attributes.mentions.forEach((mention: Mention, mentionIndex: number) => {
          mention.skills_types.forEach((skillType: SkillType, skillTypeIndex: number) => {
            // Check if the current mentionIndex matches the activeSkillTypeIndex's index
            const activeIndex = this.activeSkillTypeIndex[mentionIndex] === skillTypeIndex ? skillTypeIndex : -1;
            this.createRadarChart(profile, skillType.skills, mentionIndex, activeIndex);
          });
        });
      });
    }
    

    getFullImagePath(relativePath: string): string {
      return this.fullImagePathService.getFullImagePath(relativePath);
    }


  toggle(event: MouseEvent) {
    this.collapsed = !this.collapsed;
    this.state = this.state === 'open' ? 'close' : 'open'; // Toggle state
    event.stopPropagation(); // Prevent event propagation
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  // Create charts
  createRadarChart(profile: Profile, skillsData: Skills[], mentionIndex: number, index: number): void {
    this.activeSkillTypeIndex[mentionIndex] = index;

    am4core.useTheme(am4themes_animated);

    const chartId = `chartdiv-${profile.id}-${mentionIndex}`;
    const chartContainer = document.getElementById(chartId);
    
    if (chartContainer) {

      // Obtén los gráficos asociados con la mención actual
      const chartsForMention = this.charts.filter(chart => chart.id === chartId);
      // Elimina los gráficos asociados con la mención actual
      chartsForMention.forEach(chart => {
        chart.dispose();
      });
      // Filtra los gráficos para mantener solo aquellos que no son de la mención actual
      this.charts = this.charts.filter(chart => chart.id !== chartId);

      const chart = am4core.create(chartId, am4charts.RadarChart);
      chart.id = chartId;
      this.charts.push(chart);

      // Set chart properties
      chart.startAngle = -90;
      chart.endAngle = 180;
      chart.innerRadius = am4core.percent(20);
      chart.numberFormatter.numberFormat = "#.#'%'";
      chartContainer.style.backgroundColor = '#ffffff';

      // Define specific colors for each category
      const categoryColors = [ 
        // * /orange
        "#ee5b36",
        "#B76557",
        "#7F6F78",
        // * /blue
        "#487998",
        "#1083b9",
        "#0e6996",

      ];
      
      // Create axes
      //const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      const categoryAxis: am4charts.CategoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.fontWeight = '500';
      categoryAxis.renderer.labels.template.fontSize = '16';
      categoryAxis.renderer.minGridDistance = 15;
      categoryAxis.renderer.labels.template.adapter.add("fill", (fill, target) => {
        return (target.dataItem.index >= 0) ? am4core.color(categoryColors[target.dataItem.index % categoryColors.length]) : fill;
      });

      //const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      const valueAxis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;
      //valueAxis.alignLabels = false;
      valueAxis.renderer.labels.template.bent = true;
      valueAxis.renderer.minGridDistance = 125;

     // Create series 1
      const fullSeries = chart.series.push(new am4charts.RadarColumnSeries());
      fullSeries.dataFields.valueX = "full";
      fullSeries.dataFields.categoryY = "category";
      fullSeries.clustered = false;
      fullSeries.stacked = false;
      fullSeries.columns.template.width = am4core.percent(75);
      fullSeries.columns.template.fill = am4core.color("#d6dbe1"); // Fill color
      fullSeries.columns.template.fillOpacity = 0.3;
      fullSeries.columns.template.strokeWidth = 0;
      //fullSeries.columns.template.cornerRadiusTopLeft = 20
      fullSeries.columns.template.radarColumn.cornerRadius = 200


      // Create series 2
      const seriesData = chart.series.push(new am4charts.RadarColumnSeries());
      seriesData.dataFields.valueX = "value";
      seriesData.dataFields.categoryY = "category";
      seriesData.clustered = false;
      seriesData.columns.template.width = am4core.percent(75); // Set width to 100%
      seriesData.columns.template.strokeWidth = 0;
      seriesData.columns.template.tooltipText = "{category}: [bold]{value}[/]";
      //seriesData.columns.template.radarColumn.cornerRadiusTopLeft = 20;
      seriesData.columns.template.radarColumn.cornerRadius = 200;

      // Intenta adjuntar un evento de clic a un elemento HTML circundante
      const modalButton = document.getElementById("modalButton");
      if (modalButton) {
        modalButton.addEventListener("click", () => {
          // Código para abrir el modal
        });
      }

      seriesData.columns.template.adapter.add("fill", (fill, target) => {
        const dataItem = target.dataItem;
        return dataItem ? am4core.color(categoryColors[dataItem.index % categoryColors.length]) : fill;
      });

      seriesData.columns.template.adapter.add("tooltipText", (tooltipText, target) => {
        if (tooltipText) {
          tooltipText = `[bold]{category}[/]: {value}`;

          const tooltip = target.tooltip;
          if (tooltip) {
            tooltip.pointerOrientation = "down";
          }
        }
        return tooltipText;
      });

      seriesData.columns.template.events.on("hit", (event) => {
        const target = event.target;
        if (target) {
          const tooltip = target.tooltip;
          if (tooltip) {
            tooltip.show(); // Show the tooltip when the series is tapped
          }
        }
      });

      // Add data
      chart.data = skillsData.map(skill => ({
        category: skill.skill_name,
        value: Number(skill.percent),
        full: 100
      }));

      // Add cursor
      chart.cursor = new am4charts.RadarCursor();
      chart.cursor.lineY.visible = false;

    } else {
      console.error(`HTML container '${chartId}' not found`);
    }

  }
  
}