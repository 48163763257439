import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

export interface ReferenceItem {
  name: string;
  text: string;
  mail: string;
  person: string;
  position: string;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'scale(1)',
          opacity: 1,
        })
      ),
      state(
        'close',
        style({
          transform: 'scale(0)',
          opacity: 0,
        })
      ),
      transition('open => close', [animate('0.25s ease-in')]),
      transition('close => open', [animate('0.25s ease-out')]),
    ]),
  ],
})
export class ModalComponent {
  @Input() modalcontent: ReferenceItem | null | undefined;
  @Input() modalClass: string = '';
  @Input() showModal: boolean = false;
  @Input() imageUrl: string | null = null;
  @Output() toggleModal = new EventEmitter<boolean>();

  toggle(): void {
    this.toggleModal.emit();
    
  }

  closeButton(): void {
    this.toggleModal.emit(false);
  }
 
}