<div class="relative flex items-center">
    <svg @fade class="svg">
      <clipPath id="clip-path" clipPathUnits="objectBoundingBox"><path d="M0,0 H0.304 L1,0.323 V0.703 L0.304,1 H0"></path></clipPath>
      <clipPath id="md-clip-path" clipPathUnits="objectBoundingBox"><path d="M0,0 H0.368 L1,0.183 V0.633 L0.368,1 H0"></path></clipPath>
      <clipPath id="xs-clip-path" clipPathUnits="objectBoundingBox"><path d="M0,0 H0.551 L1,0.219 V0.598 L0.551,1 H0"></path></clipPath>
    </svg>
    <div class="clipped"></div>

    <div class="px-3 md:px-20 z-[0] absolute mt-5 lg:mt-10" *ngFor="let profile of profiles">
      <small class="text-gray-200 font-bold">&commat;{{ profile.attributes.nickname }}</small>
      <h1 class="text-3xl lg:text-4xl xl:text-5xl xl:leading-[1.2]">{{ profile.attributes.name }}</h1>
      <h2 class="text-white mt-2 font-oneday text-lg lg:text-2xl xl:text-4xl">{{ profile.attributes.profession }}</h2>
      <button class="btn primary" routerLink="../profile">View Profile</button>
      <!-- <div class="flex flex-row flex-wrap items-center mt-4 lg:mt-8 w-1/2 md:w-3/4">
         <div *ngFor="let mention of mentions" class="max-w-fit mr-2 mt-2 px-3 py-1 md:px-4 md:py-2 font-light text-sm text-orange-400 border-[1px] border-solid border-orange-400 rounded-full bg-blue-800/60 md:bg-transparent">
            {{ mention }}
          </div>
      </div> -->
    </div>
</div>

<div class="swiper-container">
  <div class="swiper-wrapper">
    <div class="swiper-slide py-5 px-4 md:p-12" *ngFor="let reference of references" (click)="openModal(reference)">
      <div class="ref cursor-[url(./assets/read_pointer.svg),_pointer]">
        <img class="w-[35px] md:w-[45px] absolute -top-5" src="../../assets/quotes.svg" alt="">
        <img class="w-[35px] md:w-[45px] absolute right-8 -bottom-5 md:right-11 rotate-180" src="../../assets/quotes.svg" alt="">
        <p class="line-clamp-5" [innerHTML]="reference.text"></p>
        <a class="ref_person" href="mailto:{{ reference.mail }}">
          <p>{{ reference.person }}</p>
          <small>{{ reference.position }} | {{ reference.name }}</small>
        </a>
      </div>
    </div>
  </div>
</div>
<app-modal [showModal]="showModal" (toggleModal)="toggleModal($event)" [modalcontent]="selectedReference" modalClass="modal-reference"></app-modal>


<!-- COMPANIES LOGOS -->
<!-- <div class="swiper-container">
  <div class="swiper-wrapper cursor-[url(./assets/scroll_x.svg),_pointer]">
    <div class="flex flex-nowrap items-center w-full my-14">
      <img *ngFor="let logo of getCompanies()" class="px-10" [src]="logo.imageUrl" [alt]="logo.imageName">
    </div>
  </div>
</div> -->

<div class="swiper-container" #swiperContainer 
  (mouseenter)="handleMouseEnter()" 
  (mouseleave)="handleMouseLeave()" 
  [ngClass]="{'cursor-left': isLeftEnd, 'cursor-right': isRightEnd}">
  <div class="swiper-wrapper" 
    #swiperWrapper
    (scroll)="handleScroll()"
    >
    <div class="flex flex-nowrap items-center w-full my-14">
      <img *ngFor="let logo of getCompanies()" class="px-10" [src]="logo.imageUrl" alt="Company Logo">
    </div>
  </div>
</div>







