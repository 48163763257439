import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { fade } from '../animations';
import { FullImagePathService } from '../services/full-image-path.service';

interface Profile{
  id: number;
  attributes: {
    nickname: string;
    name: string; 
    profession: string;
    slug: string;
    mentions: string[];
    references: string[];
    companies: string[];

  }
}
interface ReferenceItem {
  name: string;
  text: string;
  mail: string;
  person: string;
  position: string;
}

interface CompaniesLogo {
  imageUrl: string;
  imageName: string;
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  animations: [ fade, ]
})

export class HomeComponent implements OnInit {

  @ViewChild('swiperContainer') swiperContainer!: ElementRef;
  @ViewChild('swiperWrapper') swiperWrapper!: ElementRef;


  isLeftEnd: boolean = false;
  isRightEnd: boolean = false;

  profiles: any[] = [];
  mentions: string[] = [];
  references: ReferenceItem[] = [];
  selectedReference: ReferenceItem | null = null;
  showModal = false;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private fullImagePathService: FullImagePathService
  ) {}
  
  ngOnInit(): void {
    this.apiService.getProfileData().subscribe({
      next: (data) => {
        this.profiles = data.data;
        this.references = this.extractReferences(data);
      },
      error: (error) => {
        console.error('Error fetching profiles:', error);
      },
      complete: () => {
        // Optional complete callback
      },
    });
  }

  extractReferences(data: any): ReferenceItem[] {
    // Extract references from API response
    const profiles = data.data;
    const references: ReferenceItem[] = [];

    profiles.forEach((profile: any) => {
      profile.attributes.references.forEach((ref: any) => {
        references.push({
          name: ref.reference_name,
          text: ref.reference_text,
          mail: ref.reference_mail,
          person: ref.reference_person,
          position: ref.reference_position,
        });
      });
    });

    return references;
  }
  

  getCompanies(): CompaniesLogo[] {
    const companies: CompaniesLogo[] = [];
    
    // Iterate over profiles and extract company data
    this.profiles.forEach(profile => {
      profile.attributes.companies.data.forEach((company: any) => {
        companies.push({
          imageUrl: this.getFullImagePath(company.attributes.url), // Obtén la ruta completa de la imagen
          imageName: company.attributes.name, // Obtén la ruta completa de la imagen
        });
      });
    });
    
    return companies;
  }
  

  openModal(reference: ReferenceItem): void {
    this.selectedReference = reference;
    this.showModal = true;
  }

  toggleModal(showModal: boolean): void {
    this.showModal = showModal;
    if (!this.showModal) {
      this.selectedReference = null;
    }
  }

  getFullImagePath(relativePath: string): string {
    return this.fullImagePathService.getFullImagePath(relativePath);
  }

  handleMouseEnter() {
    this.updateScrollState();
  }

  handleMouseLeave() {
    this.isLeftEnd = false;
    this.isRightEnd = false;
  }

  updateScrollState() {
    const swiperWrapper = this.swiperWrapper.nativeElement as HTMLElement;
    const scrollLeft = swiperWrapper.scrollLeft;
    const scrollWidth = swiperWrapper.scrollWidth;
    const clientWidth = swiperWrapper.clientWidth;
  
    // Verificar si el scroll está en el extremo izquierdo o derecho
    this.isLeftEnd = scrollLeft === 0;
    this.isRightEnd = scrollLeft + clientWidth >= scrollWidth - 1; // Restamos 1 para evitar problemas con redondeo
  }

  handleScroll() {
    this.updateScrollState();
  }
  
  
}
