<main>
    <form (ngSubmit)="sendEmail()" [formGroup]="contactForm">
       <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-20 mb-10">
              <div class="">
                         <div class="mb-8">
                            <label for="name" class="block">Firstname:</label>
                            <div class="bg-gradient-to-r from-orange-400/70 to-blue-500/70 pb-1">
                               <input type="text" id="name" name="name" formControlName="name" required /> 
                            </div>
                            <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched"
                                 class="control chip">Firstname is required</div>
                          </div>
                          
                          <div class="mb-8">
                            <label for="lastname" class="block">Lastname:</label>
                            <div class="bg-gradient-to-r from-orange-400/70 to-blue-500/70 pb-1">
                                <input type="text" id="lastname" name="lastname" formControlName="lastname" required />
                            </div>
                            <div *ngIf="contactForm.get('lastname')?.invalid && contactForm.get('lastname')?.touched"
                                 class="control chip">Lastname is required</div>
                          </div>
                          
                          <div class="mb-8">
                            <label for="company" class="block">Company:</label>
                            <div class="bg-gradient-to-r from-orange-400/70 to-blue-500/70 pb-1">
                                <input type="text" id="company" name="company" formControlName="company" required />
                            </div>
                            <div *ngIf="contactForm.get('company')?.invalid && contactForm.get('company')?.touched"
                                 class="control chip">Company is required</div>
                          </div>
                          
                          <div class="mb-8">
                              <label for="email" class="block">Email:</label>
                              <div class="bg-gradient-to-r from-orange-400/70 to-blue-500/70 pb-1">
                                <input type="email" id="email" name="email" formControlName="email" required
                                pattern="[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}"
                                placeholder="youremail@email.com"
                                />
                              </div>
                              <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched"
                              class="control chip">Email is required</div>
                              <div *ngIf="contactForm.controls['email'].hasError('pattern') && contactForm.controls['email'].touched"
                              class="control chip">Please enter a valid email address</div>
                          </div>
               </div>
               
               
              <div class="">
                     <div class="mb-8">
                            <label for="subject" class="block">Subject:</label>
                            <div class="bg-gradient-to-r from-orange-400/70 to-blue-500/70 pb-1">
                                <input type="text" id="subject" name="subject" formControlName="subject" required />
                            </div>
                            <div *ngIf="contactForm.get('subject')?.invalid && contactForm.get('subject')?.touched"
                                 class="control chip">Subject is required</div>
                          </div>
                        
                          <div class="">
                            <label for="message" class="block">Message:</label>
                            <div class="bg-gradient-to-r from-orange-400/70 to-blue-500/70 pb-[.11rem]">
                                <textarea rows="7" id="message" name="message" formControlName="message" required class="shadow-md"></textarea>
                            </div>
                            
                            <div *ngIf="contactForm.get('message')?.invalid && contactForm.get('message')?.touched"
                                 class="control chip">Message is required</div>
                          </div>
              </div>
       </div>
       <div class="w-full flex flex-col items-end mt-4">

        <!-- Add this line inside your form -->
        <re-captcha class="g-recaptcha" [siteKey]="reCaptchaSiteKey" (resolved)="handleCaptchaResponse($event)"></re-captcha>

        <button type="submit" class="btn primary max-w-fit mt-7"
                [disabled]="contactForm.invalid"
                >
                Send Email
        </button>
       </div>
      </form>
      
      <div class="snackbar" #snackbar></div>

  
</main>