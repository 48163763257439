import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { FullImagePathService } from  '../services/full-image-path.service';

interface Profile {
  id: number;
  attributes: {
    nickname: string;
    cv_upload: string[];
    cv_image_cover: string[];
    social_links: string[];
  }
}


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit  {
  profiles: any[] = [];
  menus: any[] = []

  currentYear!: number;  
  
  constructor(
    private apiService: ApiService,
    private fullImagePathService: FullImagePathService
  ) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    
    this.apiService.getProfileData().subscribe({
      next: (data) => {
        this.profiles = data.data;
      },
      error: (error) => {
        console.error('Error fetching profiles:', error);
      },
      complete: () => {
        // Optional complete callback
      },
    });
  }

  getFullImagePath(relativePath: string): string {
    return this.fullImagePathService.getFullImagePath(relativePath);
  }

  downloadPDF(url: string): void {
    // Open a new window with the PDF file URL
    window.open(this.fullImagePathService.getFullImagePath(url), '_blank');
  }


}
