<main>
  <div class="container text-center mb-28">
    <h1 class="text-gray-700 text-2xl lg:text-4xl">Profile</h1>
  </div>
  <div *ngFor="let profile of profiles" class="flex flex-wrap">
    <div class="w-full lg:w-1/3 xl:px-10 2xl:px-20 flex flex-col relative overflow-x-visible sm:place-items-center md:mt-0 md:grid md:grid-cols-2 lg:flex lg:grid-cols-1">
      <!-- profile description and picture -->
        <div class="relative flex flex-col items-centers overflow-visible">
          <!-- <p class="w-fit text-8xl leading-none font-black absolute -top-[65px] lg:-top-[75px] z-0 tracking-tighter ml-3">Pro</p>   -->
          <div class="z-10 rounded-full border-[10px] border-solid border-gray-300 shadow-xl w-72 h-72 bg-[url('./assets/no-pic.svg')] bg-cover bg-center">
            <img *ngIf="profile.attributes.picture && profile.attributes.picture.data && profile.attributes.picture.data.length > 0; else noPicture" class="rounded-full" [src]="getFullImagePath(profile.attributes.picture.data[0].attributes.url)" alt="{{profile.attributes.name}}" />
          </div>
          <ng-template #noPicture>
              <img class="rounded-full" src="../../assets/no-pic.svg" alt="">
          </ng-template>
         
          <!-- <p class="w-fit text-4xl leading-none bg-gray-800 rounded-xl -rotate-[7deg] text-orange-400 font-black absolute ml-3 px-5 py-2 -top-2 left-1/2 z-20">file</p>  -->
        </div>
  
        <div class="mt-10">
          <button (click)="toggle($event)" class="w-full flex items-center justify-between border-gray-200 border-dotted border-b-2"><h1>About me</h1> <fa-icon [icon]="['fas', 'chevron-down']" [class.open]="state === 'open'" [class.close]="state === 'close'" class="text-2xl text-blue-400"></fa-icon></button>
          <div class="collapsible overflow-hidden pt-3" [@collapse]="collapsed">
              <p [innerHTML]="sanitizeHtml(profile.attributes.description)"></p>
          </div>
        </div>
    </div>
  
    <!-- Mentions and Skills -->
    <div class="w-full lg:w-2/3 mt-8 lg:mt-0 lg:pr-0 lg:pl-12 flex flex-col relative">
      <div class="grid grid-cols-1 xl:grid-cols-2 place-items-center mb-10" *ngFor="let mention of profile.attributes.mentions; let mentionIndex = index">
        <div class="xl:6 2xl:px-10">
            <h1>{{ mention.m_name }}</h1>
            <ul class="flex flex-wrap items-center mt-2 mb-5">
              <li *ngFor="let tag of mention.m_tags" class="chip mb-1">{{ tag }}</li>
            </ul>
            <div *ngFor="let description of mention.m_description">
              <div *ngFor="let child of description.children">
                <p class="mt-1">{{ child.text }}</p>
              </div>
            </div>
            <h3 class="text-gray-600 mt-3">Pro Skills</h3>
            <div class="flex flex-wrap items-center">
              <button 
                *ngFor="let skillType of mention.skills_types; let index = index" 
                class="btn mr-2 px-3 pt-3 pb-2 leading-none text-base mt-1"  
                [ngClass]="{
                          'primary': activeSkillTypeIndex[mentionIndex] !== index,
                          'tertiary': activeSkillTypeIndex[mentionIndex] === index
                          }"  
                (click)="createRadarChart(profile, skillType.skills, mentionIndex, index)"
              >
                {{ skillType.cat_skills }}
              </button> 
            </div> 
        </div>
        <!-- charts -->        
        <!-- <div [id]="'chartdiv-' + profile.id + '-' + mentionIndex" class="w-80 h-80 md:h-[420px] md:w-[420px]"></div> -->
        <div [id]="'chartdiv-' + profile.id + '-' + mentionIndex" class="w-80 h-80 md:h-[420px] md:w-[420px] placeholder"></div>

      
      </div>
    </div>
  
  </div>
</main>
