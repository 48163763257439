import { animate, style, transition, trigger, state } from '@angular/animations';
import { Component, Input, ElementRef, ViewChild, HostListener, EventEmitter, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Console, log } from 'console';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDropdownComponent),
      multi: true
    }
  ],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      state('open', style({ opacity: 1 })),
      transition('void => open, open => void', animate('600ms ease-in-out'))
    ]),
    trigger('iconFade', [
      state('open', style({ transform: 'rotate(180deg)' })),
      state('closed', style({ transform: 'rotate(0deg)' })),
      transition('open <=> closed', animate('300ms ease-in-out'))
    ])
  ]
})
export class CustomDropdownComponent implements ControlValueAccessor {
  @Input() options: { id: string | number, name: string }[] = [];
  @Input() selectedOption: string | number = '';
  @Output() selectedOptionChange = new EventEmitter<string | number>();

  isOpen: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};
  
  @ViewChild('dropdown') dropdownElementRef!: ElementRef;

  constructor(
    private elementRef: ElementRef,
    ) {}

  writeValue(value: any): void {
    this.selectedOption = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implement if needed
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  selectOption(option: string | number): void {
    this.selectedOption = option;
    this.onChange(this.selectedOption); // Update ngModel value
    this.onTouched(); // Mark as touched
    this.selectedOptionChange.emit(this.selectedOption); // Emit the selected option change event
    this.isOpen = this.isOpen; // Close the dropdown after selecting an option
    console.log("Selected Option:", this.selectedOption);
  }

  getSelectedOptionName(): string {
    const selected = this.options.find(option => option.id === this.selectedOption);
    //console.log("Selected option (getSelectedOptionName):", selected);
    return selected ? selected.name : '';
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    // Check if the clicked element is outside of the dropdown
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // Close the dropdown if it's open
      if (this.isOpen) {
        this.isOpen = false;
      }
    }
  }


}
