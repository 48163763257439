import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FullImagePathService {

  constructor() { }

  getFullImagePath(relativePath: string): string {
    const baseUrl = 'https://book-strapi.miscelaneo.net';
    //const baseUrl = 'http://localhost:1337';
    return `${baseUrl}${relativePath}`;
  }
}
