import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('snackbar') snackbar: any;

  emailData: any = {};
  contactForm!: FormGroup;

  reCaptchaSiteKey: string = '6LdcecEpAAAAAOVhtmpyTpy1akK-MjP0pXnO6tGo';
  captchaResponse: string = '';

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  sendEmail(): void {
    if (this.contactForm.valid && this.captchaResponse) {
      this.emailData = this.contactForm.value;
      this.http.post<any>('https://send-email.miscelaneo.net/send-email', this.emailData)
      //this.http.post<any>('http://localhost:3000/send-email', this.emailData)
        .pipe(
          tap(() => {
            this.showSnackbar('Email sent successfully', 'success');
            this.contactForm.reset();
          }),
          catchError(error => {
            console.error('Failed to send email:', error);
            this.showSnackbar('Failed to send email', 'error');
            throw error;
          })
        )
        .subscribe();
    } else {
      this.showSnackbar('Please fill out all required fields and verify the reCAPTCHA');
    }
  }

  handleCaptchaResponse(event: any): void {
    this.captchaResponse = event;
  }

  showSnackbar(message: string, type?: 'success' | 'error'): void {
    this.snackbar.nativeElement.textContent = message;
    this.snackbar.nativeElement.classList.add('show');

    if (type) {
      this.snackbar.nativeElement.classList.add(type);
    }

    setTimeout(() => {
      this.snackbar.nativeElement.classList.remove('show');
      if (type) {
        this.snackbar.nativeElement.classList.remove(type);
      }
    }, 3000);
  }
}
