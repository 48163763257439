import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ApiService } from '../services/api.service';
import { fade, hamburguerX } from "../animations";



interface Menu {
  id: number;
  attributes: {
    title: string;
    slug: string;
  };
}

interface Profile{
  id: number;
  attributes: {
    nickname: string;
  }
}


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  animations: [ fade, hamburguerX ]
})

export class HeaderComponent implements OnInit {
  error: any | undefined;
  menus$: Observable<{ data: Menu[] }> | undefined;
  profiles: any[] = [];

  showMenus$ = true;

  scrolled: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
  ) {}

  @HostListener("window:scroll", [])
    onWindowScroll() {
        this.scrolled = window.scrollY > 40;
    }

  ngOnInit(): void {
    this.menus$ = this.apiService.getMenuData().pipe(
      catchError((error) => {
        this.error = error;
        return of({ data: [] }); // Return an empty array in case of an error
      })
    );

    this.apiService.getProfileData().subscribe({
      next: (data) => {
        this.profiles = data.data;
      },
      error: (error) => {
        console.error('Error fetching profiles:', error);
      },
      complete: () => {
        // Optional complete callback
      },
    });
  }

  toggleMenu() {
    this.showMenus$ = !this.showMenus$;
  }

  navigateToHome() {
    this.router.navigate(['/home']);
    window.scrollTo(0, 0);
  }
}